const priorityLevels = {
  1: 'text-muted', // low
  2: 'text-warning', // medium
  3: 'text-danger', // high
};

const flagIcon = 'bi bi-flag-fill mr-2';

export function getFlagColor(level) {
  return `${flagIcon} ${priorityLevels[level]}`;
}

export function hasEmptyOrNullProperties(object) {
  if (typeof object !== 'object') return;

  for (let key in object) {
    if (!object[key]) {
      if (key == 'extension_name') continue;
      return true;
    }
  }

  return false;
}

export function getDate() {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const fromDate = this.params.from ? new Date(this.params.from) : null;
  const toDate = this.params.to ? new Date(this.params.to) : null;

  if (fromDate) {
    const month = months[fromDate.getMonth()];
    const year = fromDate.getFullYear();
    const dayFrom = fromDate.getDate();
    const dayTo = toDate ? toDate.getDate() : '';

    return `${month} ${dayFrom}-${dayTo}, ${year}`;
  }

  return '';
}

export function reference() {
  let d = moment().format('L');
  let t = moment().format('LTS');

  return d + ' ' + t;
}
